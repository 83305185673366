import React from "react";
import { Link } from "gatsby-plugin-react-i18next";
import { useLocation } from "@reach/router";
import { motion } from "framer-motion";

// Assets
import ArrowUpIcon from "../images/icons/arrow-up.svg";
import ArrowDownIcon from "../images/icons/arrow-down.svg";

// Styles
import * as styles from "../styles/components/FAQ.module.css";

// Utils
import {
  revealContainerProps,
  revealContainerWithChildrenProps,
  revealVariant,
} from "../utils/animation-variants";

const MotionLink = motion(Link);

const Accordion = ({ question, answer }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <motion.div variants={revealVariant} className={styles.accordion}>
      <div
        className={styles.accordion__header}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={() => setIsOpen(!isOpen)}
        role="button"
        tabIndex="0"
      >
        <h3 className={styles.accordion__question}>{question}</h3>
        <span className={styles.accordion__icon}>
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </span>
      </div>
      {isOpen && <p className={styles.accordion__answer}>{answer}</p>}
    </motion.div>
  );
};

const FAQ = ({ title, description, FAQs, buttonText, buttonLink }) => {
  const location = useLocation();
  return (
    <section className={`${styles.sectionContainer} sectionContainer`}>
      <motion.div {...revealContainerWithChildrenProps}>
        <motion.h2 variants={revealVariant} className="section-title">
          {title}
        </motion.h2>
        <motion.p variants={revealVariant} className="section-description">
          {description}
        </motion.p>
      </motion.div>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={styles.accordionContainer}
      >
        {FAQs.map((item) => (
          <Accordion
            key={item.question}
            question={item.question}
            answer={item.answer}
          />
        ))}
      </motion.div>
      <MotionLink
        {...revealContainerProps}
        variants={revealVariant}
        to={buttonLink}
        state={{ referrer: location.href }}
        className="button button--secondary"
      >
        {buttonText}
      </MotionLink>
    </section>
  );
};

export default FAQ;
