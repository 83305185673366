import * as React from "react";
import { graphql, Link } from "gatsby";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import { useForm } from "react-hook-form";
import { useLocation } from "@reach/router";
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { motion } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";

// Components
import Seo from "../components/SEO";
import Layout from "../components/Layout";
import FAQ from "../components/FAQ";

// Assets
import BenefitsSectionIcon1 from "../images/icons/merchant-benefitsSection-1.svg";
import BenefitsSectionIcon2 from "../images/icons/merchant-benefitsSection-2.svg";
import BenefitsSectionIcon3 from "../images/icons/merchant-benefitsSection-3.svg";
import HowToStartSectionImage from "../images/icons/merchant-howToStartSection.svg";
import FileDownloadIcon from "../images/icons/file-download.svg";
import SuccessIcon from "../images/icons/contact-success.svg";
import TrainingIcon from "../images/training.svg";
import SupportIcon from "../images/support.svg";
import FaqIcon from "../images/faq.svg";

// Styles
import * as styles from "../styles/pages/store-hub.module.css";

// Utils
import {
  revealContainerWithChildrenProps,
  revealContainerProps,
  revealVariant,
} from "../utils/animation-variants";
import { Trans } from "react-i18next";
import { api } from "../utils/api";
import StoreHubForm from "../components/StoreHubForm";

const HeroSection = ({ activeCard, setActiveCard }) => {
  const { t } = useTranslation();

  const storeHubCards = [
    {
      icon: TrainingIcon,
      title: t("merchant.heroSection.cards.card1.title"),
    },
    {
      icon: FaqIcon,
      title: t("merchant.heroSection.cards.card2.title"),
    },
    {
      icon: SupportIcon,
      title: t("merchant.heroSection.cards.card3.title"),
    },
  ];

  return (
    <section className={`${styles.heroSection} `}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`text-white relative !max-w-[825px] mx-auto flex-1 w-full sectionContainer !px-4`}
      >
        <motion.h1
          variants={revealVariant}
          className={`text-[34px] font-bold leading-[41px] mb-6 mx-auto`}
        >
          <Trans i18nKey="merchant.heroSection.title">
            Welcome to every <span className="brand-color">branch manager</span>{" "}
            or <span className="brand-color">cashier</span>...
          </Trans>
        </motion.h1>
        <motion.p
          variants={revealVariant}
          className={`text-[22px] !opacity-80 mb-[38px] mt-5`}
        >
          {t("merchant.heroSection.description")}
        </motion.p>
        <motion.div
          variants={revealVariant}
          className="flex flex-row flex-wrap items-center justify-between flex-1 gap-4 mx-auto"
        >
          {storeHubCards.map((card, index) => (
            <div
              key={index}
              className={`flex cursor-pointer flex-col gap-5 bg-white rounded-xl px-4 h-[174px] 
              justify-between items-center py-8 basis-[98px] flex-1 md:flex-[none] lg:max-w-full md:py-8 md:basis-[166px] ${activeCard == index ? "!bg-[#FFD860]" : ""
                }`}
              onClick={() => setActiveCard(index)}
            >
              {<img className="w-[55px] h-[55px]" src={card.icon} alt={card.title} />}
              <p className={`text-center text-black text-[22px] font-semibold`}>
                {card.title}
              </p>
            </div>
          ))}
        </motion.div>
      </motion.div>
      {/* <MouseScrollAnimated /> */}
    </section>
  );
};

const HowItWorksSection = ({ images }) => {
  const { t } = useTranslation();
  const HowItWorksSectionData = [
    {
      title: t("merchant.howItWorksSection.step1.title"),
      image: getImage(images[0].node.childrenImageSharp[0]),
    },
    {
      title: t("merchant.howItWorksSection.step2.title"),
      image: getImage(images[1].node.childrenImageSharp[0]),
    },
    {
      title: t("merchant.howItWorksSection.step3.title"),
      image: getImage(images[2].node.childrenImageSharp[0]),
    },
    {
      title: t("merchant.howItWorksSection.step4.title"),
      image: getImage(images[3].node.childrenImageSharp[0]),
    },
  ];

  return (
    <section
      id="how-it-works"
      className={`${styles.howItWorksSection} sectionContainer`}
    >
      <motion.div {...revealContainerWithChildrenProps}>
        <motion.h2 variants={revealVariant} className="section-title">
          {t("merchant.howItWorksSection.title")}
        </motion.h2>
        <motion.p variants={revealVariant} className="section-description">
          {t("merchant.howItWorksSection.description")}
        </motion.p>
      </motion.div>
      <div className={styles.howItWorksSection__cardsContainer}>
        {HowItWorksSectionData.map((item, index) => (
          <motion.div
            {...revealContainerProps}
            variants={revealVariant}
            key={item.title}
            className={styles.howItWorksSection__card}
          >
            <span className={styles.howItWorksSection__number}>
              {t(`${index + 1}`)}
            </span>
            <p className={styles.howItWorksSection__cardTitle}>{item.title}</p>
            <GatsbyImage
              className={styles.howItWorksSection__cardImg}
              image={item.image}
              alt={item.title}
            />
          </motion.div>
        ))}
      </div>
    </section>
  );
};

const BenefitsSection = () => {
  const { t } = useTranslation();
  const BenefitsSectionData = [
    {
      icon: <BenefitsSectionIcon1 />,
      title: t("merchant.benefitsSection.item1.title"),
      description: t("merchant.benefitsSection.item1.description"),
    },
    {
      icon: <BenefitsSectionIcon2 />,
      title: t("merchant.benefitsSection.item2.title"),
      description: t("merchant.benefitsSection.item2.description"),
    },
    {
      icon: <BenefitsSectionIcon3 />,
      title: t("merchant.benefitsSection.item3.title"),
      description: t("merchant.benefitsSection.item3.description"),
    },
  ];
  return (
    <section className={`${styles.benefitsSection} sectionContainer`}>
      <motion.div {...revealContainerWithChildrenProps}>
        <motion.h2 variants={revealVariant} className="section-title">
          {t("merchant.benefitsSection.title")}
        </motion.h2>
        <motion.p variants={revealVariant} className="section-description">
          {t("merchant.benefitsSection.description")}
        </motion.p>
      </motion.div>
      <div className={styles.benefitsSection__cardsContainer}>
        {BenefitsSectionData.map((item) => (
          <motion.div
            {...revealContainerProps}
            variants={revealVariant}
            key={item.title}
            className={styles.benefitsSection__card}
          >
            <div className={styles.benefitsSection__cardIconContainer}>
              {item.icon}
            </div>
            <p className={styles.benefitsSection__cardTitle}>{item.title}</p>
            <p className={styles.benefitsSection__cardDescription}>
              {item.description}
            </p>
          </motion.div>
        ))}
      </div>
    </section>
  );
};

const HowToStartSection = () => {
  const { t } = useTranslation();
  return (
    <section className={`${styles.howToStartSection} !max-w-[825px] mx-auto`}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className={`${styles.howToStartSection__container} sectionContainer`}
      >
        <motion.div
          variants={revealVariant}
          className={styles.howToStartSection__imageContainer}
        >
          <HowToStartSectionImage />
        </motion.div>
        <motion.div
          variants={revealVariant}
          className={styles.howToStartSection__content}
        >
          <h2 className="section-title !leading-snug">
            {t("merchant.howToStartSection.title")}
          </h2>
          <p className="section-description">
            {t("merchant.howToStartSection.description")}
          </p>
          <Link
            href="/files/NI-training.pdf"
            download
            className="button button--gradient"
          >
            {t("merchant.howToStartSection.button")}
            <span>
              <FileDownloadIcon />
            </span>
          </Link>
        </motion.div>
      </motion.div>
    </section>
  );
};

const NewsletterSection = () => {
  const { t, language } = useI18next();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const location = useLocation();
  const fullUrl = location?.state?.referrer || location?.href;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await fetch(`${api}/v3/hub`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({
        name: data.name,
        phone: data.phone,
        merchant: data.company,
        branch: data.branch,
        fullUrl
      }),
    });
    if (response.status === 200) {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "formSubmitted",
          formName: "LG_Storehub",
          formPosition: "body",
        });
      }
      setIsFormSubmitted(true);
    }

    // TODO: Error Handling
    setIsLoading(false);
  };
  if (isFormSubmitted) {
    return (
      <section
        className={`${styles.formSuccessSection} sectionContainer max-w-[825px] mx-auto`}
      >
        <div className={styles.formSuccessSection__icon}>
          <SuccessIcon />
        </div>
        <h2 className={styles.formSuccessSection__title}>
          {t("merchant.form.success.title")}
        </h2>
        <p className={styles.formSuccessSection__description}>
          {t("merchant.form.success.description")}
        </p>
      </section>
    );
  }
  return (
    <section className={`${styles.newsletterSection} max-w-[1140px] mx-auto`}>
      <motion.div
        {...revealContainerWithChildrenProps}
        className="sectionContainer "
      >
        <h2 className={styles.newsletterSection__title}>
          {t("merchant.newsletterSection.title")}
        </h2>
        <p className={styles.newsletterSection__description}>
          {t("merchant.newsletterSection.description")}
        </p>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className={styles.newsletterSection__form}>
            <motion.div variants={revealVariant}>
              <label
                htmlFor="name"
                className={styles.newsletterSection__form__item__label}
              >
                {t("merchant.newsletterSection.form.name.label")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="name"
                placeholder={t(
                  "merchant.newsletterSection.form.name.placeholder"
                )}
                className={`input ${errors.name ? "error" : ""}`}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <span className={styles.newsletterSection__form__error}>
                  {errors.name.type === "required"
                    ? t("merchant.newsletterSection.form.error.required")
                    : t("merchant.newsletterSection.form.error.validation", {
                      field: t(
                        "merchant.newsletterSection.form.name.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>

            <motion.div variants={revealVariant}>
              <label
                htmlFor="company"
                className={styles.newsletterSection__form__item__label}
              >
                {t("merchant.newsletterSection.form.company.label")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="name"
                placeholder={t(
                  "merchant.newsletterSection.form.company.placeholder"
                )}
                className={`input ${errors.company ? "error" : ""}`}
                {...register("company", { required: true })}
              />
              {errors.company && (
                <span className={styles.newsletterSection__form__error}>
                  {errors.company.type === "required"
                    ? t("merchant.newsletterSection.form.error.required")
                    : t("merchant.newsletterSection.form.error.validation", {
                      field: t(
                        "merchant.newsletterSection.form.company.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
            <motion.div variants={revealVariant}>
              <label
                htmlFor="phone"
                className={styles.newsletterSection__form__item__label}
              >
                {t("merchant.newsletterSection.form.phone.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t(
                  "merchant.newsletterSection.form.phone.placeholder"
                )}
                type="text"
                id="phone"
                className={`input ${errors.phone ? "error" : ""}`}
                {...register("phone", {
                  required: true,
                  pattern: /^\+{0,1}[0-9]+$/,
                })}
              />
              {errors.phone && (
                <span className={styles.newsletterSection__form__error}>
                  {errors.phone.type === "required"
                    ? t("merchant.newsletterSection.form.error.required")
                    : t("merchant.newsletterSection.form.error.validation", {
                      field: t(
                        "merchant.newsletterSection.form.phone.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
            <motion.div variants={revealVariant}>
              <label
                htmlFor="branch"
                className={styles.newsletterSection__form__item__label}
              >
                {t("merchant.newsletterSection.form.branch.label")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="branch"
                placeholder={t(
                  "merchant.newsletterSection.form.branch.placeholder"
                )}
                className={`input ${errors.branch ? "error" : ""}`}
                {...register("branch", { required: true })}
              />
              {errors.branch && (
                <span className={styles.newsletterSection__form__error}>
                  {errors.branch.type === "required"
                    ? t("merchant.newsletterSection.form.error.required")
                    : t("merchant.newsletterSection.form.error.validation", {
                      field: t(
                        "merchant.newsletterSection.form.branch.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
          </div>
          <motion.div
            {...revealContainerProps}
            variants={revealVariant}
            className={styles.newsletterSection__form__submit_wrapper}
          >
            <button
              type="submit"
              className={`button button--secondary ${styles.newsletterSection__form__submit}`}
            >
              {isLoading ? (
                <ThreeDots
                  height="28"
                  width="28"
                  color="#fff"
                  ariaLabel="loading"
                  radius="3"
                  visible={true}
                  wrapperStyle={{ justifyContent: "center" }}
                />
              ) : (
                t("merchant.newsletterSection.form.submit")
              )}
            </button>
          </motion.div>
        </form>
      </motion.div>
    </section>
  );
};

const MerchantPage = ({
  data: { howItWorksSectionImagesAR, howItWorksSectionImagesEN },
}) => {
  const { t, language } = useI18next();
  const [activeCard, setActiveCard] = React.useState(0);

  const faqData = [
    {
      question: t("merchant.FAQSection.question1"),
      answer: t("merchant.FAQSection.answer1"),
    },
    {
      question: t("merchant.FAQSection.question2"),
      answer: t("merchant.FAQSection.answer2"),
    },
    {
      question: t("merchant.FAQSection.question3"),
      answer: t("merchant.FAQSection.answer3"),
    },
  ];

  const activeSections = [
    <HowToStartSection />,
    <FAQ
      title={t("merchant.FAQSection.title")}
      FAQs={faqData}
      buttonLink="/contact-us"
      buttonText={t("merchant.section5.button")}
    />,
    <StoreHubForm />,
  ];

  return (
    <Layout>
      <HeroSection activeCard={activeCard} setActiveCard={setActiveCard} />

      <div className="mt-[58px] mb-[70px] md:px-0 px-2">
        {activeSections[activeCard]}
      </div>
    </Layout>
  );
};

export default MerchantPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "store-hub", "contact"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    howItWorksSectionImagesAR: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/merchant-step-ar/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 192
            )
          }
        }
      }
    }

    howItWorksSectionImagesEN: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { regex: "/merchant-step-en/" }
      }
      sort: { order: ASC, fields: name }
    ) {
      edges {
        node {
          childrenImageSharp {
            gatsbyImageData(
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              width: 192
            )
          }
        }
      }
    }
  }
`;

export const Head = ({ pageContext }) => {
  const isArabic = pageContext.i18n.language === "ar";
  return (
    <Seo isArabic={isArabic} title={isArabic ? "مركز المتجر" : "Store Hub"} />
  );
};
