import * as React from "react";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";
import { motion } from "framer-motion";
import { useForm } from "react-hook-form";
import { ThreeDots } from "react-loader-spinner";

// Styles
import * as styles from "../styles/pages/contact.module.css";
import SuccessIcon from "../images/icons/contact-success.svg";

// Utils

import {
  revealContainerProps,
  revealVariant,
  revealContainerWithChildrenProps,
} from "../utils/animation-variants";

import { api } from "../utils/api";

const StoreHubForm = ({ title, description, blogs }) => {
  const { t, language } = useI18next();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [isFormSubmitted, setIsFormSubmitted] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const onSubmit = async (data) => {
    setIsLoading(true);
    const response = await fetch(`${api}/v3/hub`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Accept-Language": language,
      },
      body: JSON.stringify({
        name: data.fullName,
        branch: data.branch,
        phone: data.phone,
        merchant: data.merchant,
      }),
    });
    if (response.status === 200) {
      if (window && window.dataLayer) {
        window.dataLayer.push({
          event: "formSubmitted",
          formName: "LG_Storehub",
          formPosition: "body",
        });
      }
      setIsFormSubmitted(true);
    }

    // TODO: Error Handling
    setIsLoading(false);
  };
  if (isFormSubmitted) {
    return (
      <section className={`${styles.formSuccessSection} sectionContainer`}>
        <div className={styles.formSuccessSection__icon}>
          <SuccessIcon />
        </div>
        <h2 className={styles.formSuccessSection__title}>
          {t("contact.form.success.title")}
        </h2>
        <p className={styles.formSuccessSection__description}>
          {t("contact.form.success.description")}
        </p>
        <Link to="/" className="button button--secondary">
          {t("contact.form.success.button")}
        </Link>
      </section>
    );
  }
  return (
    <motion.section
      {...revealContainerProps}
      variants={revealVariant}
      className={`${styles.formSection} sectionContainer`}
    >
      <h2 className={styles.formSection__title}>
        {t("contact.formSection.title")}
      </h2>
      <p className={styles.formSection__description}>
        {t("contact.formSection.description")}
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.formSection__form}>
          <motion.div
            {...revealContainerWithChildrenProps}
            className={`${styles.formSection__form__col} !grid-rows-2`}
          >
            <motion.div variants={revealVariant}>
              <label
                htmlFor="fullName"
                className={`${styles.formSection__form__item__label} ${styles.dark_label} `}
              >
                {t("contact.formSection.form.fullName.label")}
                <span>*</span>
              </label>
              <input
                type="text"
                id="branch"
                placeholder={t("contact.formSection.form.fullName.placeholder")}
                className={`input  ${errors.branch ? "error" : ""}`}
                {...register("fullName", { required: true })}
              />
              {errors.branch && (
                <span className={styles.formSection__form__error}>
                  {errors.fullName.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.fullName.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
            <motion.div variants={revealVariant}>
              <label
                htmlFor="phone"
                className={`${styles.formSection__form__item__label} ${styles.dark_label} `}
              >
                {t("contact.formSection.form.phone.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t("contact.formSection.form.phone.placeholder")}
                type="text"
                id="phone"
                className={`input  ${errors.phone ? "error" : ""}`}
                {...register("phone", { required: true })}
              />
              {errors.phone && (
                <span className={styles.formSection__form__error}>
                  {errors.phone.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.phone.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
          </motion.div>
          <motion.div
            {...revealContainerWithChildrenProps}
            className={`${styles.formSection__form__col} !grid-rows-2`}
          >
            <motion.div variants={revealVariant}>
              <label
                htmlFor="merchant"
                className={`${styles.formSection__form__item__label} ${styles.dark_label} `}
              >
                {t("contact.formSection.form.merchant.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t("contact.formSection.form.merchant.placeholder")}
                type="text"
                id="merchant"
                className={`input  ${errors.merchant ? "error" : ""}`}
                {...register("merchant", { required: true })}
              />
              {errors.company && (
                <span className={styles.formSection__form__error}>
                  {errors.merchant.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.merchant.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
            <motion.div variants={revealVariant}>
              <label
                htmlFor="merchant"
                className={`${styles.formSection__form__item__label} ${styles.dark_label} `}
              >
                {t("contact.formSection.form.branch.label")}
                <span>*</span>
              </label>
              <input
                placeholder={t("contact.formSection.form.branch.placeholder")}
                type="text"
                id="branch"
                className={`input  ${errors.merchant ? "error" : ""}`}
                {...register("branch", { required: true })}
              />
              {errors.company && (
                <span className={styles.formSection__form__error}>
                  {errors.branch.type === "required"
                    ? t("contact.formSection.form.error.required")
                    : t("contact.formSection.form.error.validation", {
                      field: t(
                        "contact.formSection.form.branch.label"
                      ).toLowerCase(),
                    })}
                </span>
              )}
            </motion.div>
          </motion.div>
        </div>
        <motion.div
          {...revealContainerProps}
          variants={revealVariant}
          className={styles.formSection__form__submit_wrapper}
        >
          <button
            type="submit"
            className={`button button--secondary ${styles.formSection__form__submit}`}
          >
            {isLoading ? (
              <ThreeDots
                height="28"
                width="28"
                color="#fff"
                ariaLabel="loading"
                radius="3"
                visible={true}
              />
            ) : (
              t("contact.formSection.form.submit")
            )}
          </button>
        </motion.div>
      </form>
    </motion.section>
  );
};

export default StoreHubForm;
