// extracted by mini-css-extract-plugin
export var FAQSection = "store-hub-module--FAQSection--695cd";
export var FAQSection__accordionContainer = "store-hub-module--FAQSection__accordionContainer--1444b";
export var accordion = "store-hub-module--accordion--f0786";
export var accordion__answer = "store-hub-module--accordion__answer--06ee7";
export var accordion__header = "store-hub-module--accordion__header--0104a";
export var accordion__icon = "store-hub-module--accordion__icon--f2703";
export var accordion__question = "store-hub-module--accordion__question--6151f";
export var benefitsSection = "store-hub-module--benefitsSection--c122f";
export var benefitsSection__card = "store-hub-module--benefitsSection__card--8e3df";
export var benefitsSection__cardDescription = "store-hub-module--benefitsSection__cardDescription--28d52";
export var benefitsSection__cardIconContainer = "store-hub-module--benefitsSection__cardIconContainer--52d11";
export var benefitsSection__cardTitle = "store-hub-module--benefitsSection__cardTitle--3e77f";
export var benefitsSection__cardsContainer = "store-hub-module--benefitsSection__cardsContainer--6db59";
export var formSuccessSection = "store-hub-module--formSuccessSection--ca2bf";
export var formSuccessSection__description = "store-hub-module--formSuccessSection__description--15e82";
export var formSuccessSection__icon = "store-hub-module--formSuccessSection__icon--2f6ef";
export var formSuccessSection__title = "store-hub-module--formSuccessSection__title--486d7";
export var heroSection = "store-hub-module--heroSection--8cb75";
export var heroSection__backgroundImg = "store-hub-module--heroSection__backgroundImg--18c0b";
export var heroSection__content = "store-hub-module--heroSection__content--00b9b";
export var heroSection__description = "store-hub-module--heroSection__description--15e69";
export var heroSection__title = "store-hub-module--heroSection__title--2a85c";
export var howItWorksSection = "store-hub-module--howItWorksSection--f0d38";
export var howItWorksSection__card = "store-hub-module--howItWorksSection__card--4c7ab";
export var howItWorksSection__cardImg = "store-hub-module--howItWorksSection__cardImg--5aab7";
export var howItWorksSection__cardTitle = "store-hub-module--howItWorksSection__cardTitle--728fa";
export var howItWorksSection__cardsContainer = "store-hub-module--howItWorksSection__cardsContainer--0e5a5";
export var howItWorksSection__number = "store-hub-module--howItWorksSection__number--c3fee";
export var howToStartSection = "store-hub-module--howToStartSection--bb28c";
export var howToStartSection__container = "store-hub-module--howToStartSection__container--18206";
export var howToStartSection__content = "store-hub-module--howToStartSection__content--ebe6a";
export var howToStartSection__imageContainer = "store-hub-module--howToStartSection__imageContainer--3d9cc";
export var merchant__downloadBtn = "store-hub-module--merchant__downloadBtn--ce3cf";
export var newsletterSection = "store-hub-module--newsletterSection--92b5a";
export var newsletterSection__description = "store-hub-module--newsletterSection__description--b0d7e";
export var newsletterSection__form = "store-hub-module--newsletterSection__form--fcfaa";
export var newsletterSection__form__error = "store-hub-module--newsletterSection__form__error--752bc";
export var newsletterSection__form__item__label = "store-hub-module--newsletterSection__form__item__label--771f7";
export var newsletterSection__form__submit = "store-hub-module--newsletterSection__form__submit--7be2f";
export var newsletterSection__form__submit_wrapper = "store-hub-module--newsletterSection__form__submit_wrapper--a82f5";
export var newsletterSection__title = "store-hub-module--newsletterSection__title--e334b";