// extracted by mini-css-extract-plugin
export var dark_label = "contact-module--dark_label--73bea";
export var formSection = "contact-module--formSection--7b90d";
export var formSection__description = "contact-module--formSection__description--5d611";
export var formSection__form = "contact-module--formSection__form--76b3e";
export var formSection__form__col = "contact-module--formSection__form__col--e4bb4";
export var formSection__form__error = "contact-module--formSection__form__error--4de99";
export var formSection__form__item__label = "contact-module--formSection__form__item__label--19543";
export var formSection__form__item__textarea = "contact-module--formSection__form__item__textarea--fa2fb";
export var formSection__form__item__textarea__wrapper = "contact-module--formSection__form__item__textarea__wrapper--56f1a";
export var formSection__form__submit = "contact-module--formSection__form__submit--b58e2";
export var formSection__form__submit_wrapper = "contact-module--formSection__form__submit_wrapper--ba7b7";
export var formSection__title = "contact-module--formSection__title--9cc33";
export var formSuccessSection = "contact-module--formSuccessSection--7bd38";
export var formSuccessSection__description = "contact-module--formSuccessSection__description--48f29";
export var formSuccessSection__icon = "contact-module--formSuccessSection__icon--9cc6a";
export var formSuccessSection__title = "contact-module--formSuccessSection__title--08edd";
export var section1 = "contact-module--section1--66dc2";
export var section1__content = "contact-module--section1__content--44533";
export var section1__content__wrapper = "contact-module--section1__content__wrapper--491a8";
export var section1__description = "contact-module--section1__description--3be14";
export var section1__item = "contact-module--section1__item--3351c";
export var section1__item__icon = "contact-module--section1__item__icon--35d33";
export var section1__item__text = "contact-module--section1__item__text--eb995";
export var section1__items = "contact-module--section1__items--66d71";
export var section1__title = "contact-module--section1__title--c48b6";